/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from 'prop-types';
import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

export default function Link(props) {
  const router = useRouter();

  // TBD: Add support for replace, shallow
  async function handleClick(e) {
    e.preventDefault();
    await props.onClick();
    router.push(props.href);
  }

  if (props.disabled) {
    return (
      <a
        role="link"
        aria-disabled="true"
        style={props.style}
        data-testid={props['data-testid']}
        aria-label={props['aria-label']}
        className={props.className}
      >
        {props.children}
      </a>
    );
  }

  return props.onClick ? (
    <a
      style={props.style}
      onClick={e => handleClick(e)}
      data-testid={props['data-testid']}
      aria-label={props['aria-label']}
      className={props.className}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    >
      {props.children}
    </a>
  ) : (
    <NextLink
      as={props.as}
      href={props.href}
      passHref={props.passHref}
      prefetch={props.prefetch}
      replace={props.replace}
      scroll={props.scroll}
      shallow={props.shallow}
      legacyBehavior
    >
      <a
        className={props.className}
        style={{ flexShrink: 0, ...(props.style || {}) }}
        aria-label={props['aria-label']}
        data-testid={props['data-testid']}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      >
        {props.children}
      </a>
    </NextLink>
  );
}

Link.propTypes = {
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  as: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  passHref: PropTypes.bool,
  disabled: PropTypes.bool,
  prefetch: PropTypes.bool,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  'aria-label': PropTypes.string
};

Link.defaultProps = {
  as: null,
  className: undefined,
  onClick: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
  passHref: false,
  prefetch: undefined,
  replace: false,
  scroll: true,
  shallow: false,
  style: {}
};
