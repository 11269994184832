import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';

export default function BodyBottombarPortal(props) {
  const [portalTarget, setPortalTarget] = useState(null);

  useEffect(() => {
    setPortalTarget(document.getElementById('bottombar'));
  }, []);

  return portalTarget && ReactDOM.createPortal(props.children, portalTarget);
}

BodyBottombarPortal.propTypes = {
  children: PropTypes.node
};

BodyBottombarPortal.defaultProps = {
  children: undefined
};
