import { useSelector } from 'react-redux';
import selectDefaultProvider from 'shared/Redux/selectors/provider/selectDefaultProvider';

export default function useDefaultFilter() {
  const defaultProvider = useSelector(selectDefaultProvider);

  function defaultFilter(str: string) {
    return `${str}?provider_id=${defaultProvider?.id}`;
  }

  return defaultFilter;
}
