import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';

import {
  Avatar,
  Box,
  Button,
  Drawer,
  Dropdown,
  LoadingIndicator,
  Message,
  Spacing,
  Topbar,
  Void,
  makeStyles,
  useTheme,
  useMediaQuery
} from 'groundkeeper-component-library';

import BodyBottombar from 'shared/App/components/Body/BodyBottombar';
import BodyBottombarPortal from 'shared/App/components/Body/BodyBottombarPortal';
import BodyContainer from 'shared/App/components/Body/BodyContainer';
import BodySidebar from './BodySidebar';
import BodySidebarItem from './BodySidebarItem';
import BodySidebarContainer from './BodySidebarContainer';
import Link from 'shared/App/components/Link';

import selectCurrentUserMember from 'shared/Redux/selectors/user/selectCurrentUserMember';
import selectDefaultOrganizationId from 'shared/Redux/selectors/organization/selectDefaultOrganizationId';
import selectLoggedInUserAvatar from 'shared/Redux/selectors/user/selectLoggedInUserAvatar.ts';
import selectLoggedInUserFullName from 'shared/Redux/selectors/user/selectLoggedInUserFullName.ts';
import selectOrganizationById from 'shared/Redux/selectors/organization/selectOrganizationById';
import selectUiDrawerOpen from 'shared/Redux/selectors/application/selectUiDrawerOpen';
import selectUserEmail from 'shared/Redux/selectors/user/selectUserEmail';
import selectUserHasNoMember from 'shared/Redux/selectors/user/selectUserHasNoMember';
import selectUserMemberType from 'shared/Redux/selectors/user/selectUserMemberType';

import Globals from 'shared/App/utils/Globals';
import formatImageUrl from 'shared/App/utils/formatImageUrl';
import { logout } from 'shared/Redux/slices/auth.slice';
import { toggleDrawerOpen } from 'shared/Redux/slices/application.slice';
import useDefaultFilter from 'shared/App/utils/useDefaultFilter';

// TBD: Add dynamic width calculation
export const TOPBAR_HEIGHT = '43px';
export const BOTTOMBAR_HEIGHT = '50px';
export const BOTTOMBAR_HEIGHT_COLLAPSED = '17px';

const prodEnvNames = ['PROD', 'PRODUCTION', '[PROD]', '[PRODUCTION]'];

const useStyles = makeStyles(theme => ({
  drawerWrapper: {
    position: 'sticky',
    zIndex: 97,
    top: TOPBAR_HEIGHT,
    height: `calc(100vh - ${TOPBAR_HEIGHT} - ${BOTTOMBAR_HEIGHT})`,
    [theme.mediaQueries.small]: {
      height: `calc(100vh - ${TOPBAR_HEIGHT} - ${BOTTOMBAR_HEIGHT_COLLAPSED})`
    }
  },
  drawer: {
    // Fix collapsing border with topbar
    marginTop: -1,
    boxSizing: 'border-box',
    [theme.mediaQueries.small]: {
      paddingBottom: '16px'
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    minWidth: 0
  },
  loadingIndicator: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 999
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    maxWidth: '12em',
    padding: props =>
      props.settings?.logoPadding ? `0 ${props.settings?.logoPadding}em` : 0,
    '& img, & svg': {
      maxHeight: '90%'
    },
    '&:hover': {
      background: theme.color.background.grey,
      borderColor: 'transparent'
    }
  },
  logoEnvWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1)
  },
  env: {
    color: theme.color.error.contrastText,
    background: theme.color.error.main,
    fontWeight: 700,
    padding: `${theme.spacing(0.25)} ${theme.spacing(2)}`
  },
  envDecorator: {
    borderTop: `4px solid ${theme.color.error.main}`
  },
  logoWrapper: {
    height: '2.1em',
    minWidth: '6em'
  },
  userDropdownTitle: {
    minWidth: '15rem',
    borderBottom: `1px solid ${theme.color.border.light}`
  },
  userDropdownName: {
    color: theme.color.text.main,
    fontWeight: 600
  },
  userDropdownEmail: {
    color: theme.color.text.light,
    fontSize: theme.font.size.text,
    fontWeight: 'normal'
  },
  topbarButton: {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem'
  }
}));

export default function Body(props) {
  const intl = useIntl();
  const router = useRouter();
  const dispatch = useDispatch();
  const organizationId = useSelector(selectDefaultOrganizationId);
  const organization = useSelector(selectOrganizationById(organizationId));
  const userAvatar = useSelector(selectLoggedInUserAvatar);
  const userEmail = useSelector(selectUserEmail);
  const userFullName = useSelector(selectLoggedInUserFullName);
  const userWithoutMember = useSelector(selectUserHasNoMember);
  const drawerOpen = useSelector(selectUiDrawerOpen);
  const userMemberType = useSelector(selectUserMemberType);
  const userMember = useSelector(selectCurrentUserMember);
  const defaultFilter = useDefaultFilter();
  const theme = useTheme();
  const mediaQuerySmall = useMediaQuery(theme.breakpoints.down('md'));

  let settings;

  if (userMemberType === 'consumer') {
    settings = Globals.settings_consumer;
  } else if (userMemberType === 'provider') {
    settings = Globals.settings_provider;
  } else {
    settings = Globals.settings;
  }

  const classes = useStyles({ ...props, settings });

  function handleLogout() {
    dispatch(logout());
    router.push('/login');
  }

  function getDrawerItemActive(item) {
    const pathname = item.pathname || item.href;

    if (item.submenu) {
      for (let i = 0; i < item.submenu.length; i += 1) {
        if (getDrawerItemActive(item.submenu[i])) {
          return true;
        }
      }
    }

    return router.pathname.startsWith(pathname);
  }

  function getUserOrgName() {
    switch (userMemberType) {
      case 'consumer':
        return userMember?.consumer_name;

      case 'provider':
        return userMember?.provider_name;

      case 'organization':
        return userMember?.organization_name;

      default:
        return null;
    }
  }

  function getUserOrgUrl() {
    switch (userMemberType) {
      case 'consumer':
        return `/consumers/${userMember?.consumer_id}`;

      case 'provider':
        return `/providers/${userMember?.provider_id}`;

      case 'organization':
        return `/organizations/${userMember?.organization_id}`;

      default:
        return '/';
    }
  }

  useEffect(() => {
    if (props.error?.length || props.warning?.length || props.success?.length) {
      window?.scroll(0, 0);
    }
  }, [props.error, props.warning, props.success]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box
        component="header"
        data-testid="topbar"
        width="100%"
        position="sticky"
        top={0}
        zIndex={98}
        height={TOPBAR_HEIGHT}
      >
        <Topbar
          size="medium"
          className={
            settings.appEnv &&
            !prodEnvNames.includes(settings.appEnv.toUpperCase())
              ? classes.envDecorator
              : null
          }
          logo={
            formatImageUrl(organization?.logo || settings.logo) ? (
              <div className={classes.logoEnvWrapper}>
                <div className={classes.logoWrapper}>
                  <Link href="/dashboard">
                    <div className={classes.logo}>
                      <img
                        src={formatImageUrl(
                          organization?.logo || settings.logo
                        )}
                        alt="Berlin Logo"
                        title="Link zu: Dashboard"
                      />
                    </div>
                  </Link>
                </div>

                {settings.appEnv &&
                !prodEnvNames.includes(settings.appEnv.toUpperCase()) ? (
                  <div className={classes.env}>
                    {[settings.appEnv.toUpperCase(), settings.appDisplayName]
                      .filter(x => !!x)
                      .join(' ')}
                  </div>
                ) : (
                  settings.appDisplayName
                )}
              </div>
            ) : null
          }
        >
          {!mediaQuerySmall ? (
            <>
              {settings.navigation_top(intl).map(item =>
                item.external ? (
                  <a
                    key={item.href}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className={classes.topbarButton} tabIndex="-1">
                      {item.caption}
                    </Button>
                  </a>
                ) : (
                  <a key={item.href} href={item.href}>
                    {item.caption}
                  </a>
                )
              )}
              <Link href={getUserOrgUrl()}>
                <Button
                  className={classes.topbarButton}
                  data-testid="user-org-button"
                  tabIndex="-1"
                >
                  {getUserOrgName()}
                </Button>
              </Link>
            </>
          ) : undefined}
          <Dropdown
            linkComponent={Link}
            title={
              <Box py={2} px={1} mt={1} className={classes.userDropdownTitle}>
                <Box className={classes.userDropdownName}>{userFullName}</Box>
                <Box className={classes.userDropdownEmail}>{userEmail}</Box>
              </Box>
            }
            entries={[
              {
                disabled: !userMember,
                title: intl.formatMessage({
                  id: 'settings',
                  defaultMessage: 'Settings'
                }),
                icon: 'setting',
                href: `${getUserOrgUrl()}/members/${userMember?.id}/edit`
              },
              {
                title: intl.formatMessage({
                  id: 'logout',
                  defaultMessage: 'Logout'
                }),
                'data-testid': 'logout-btn',
                icon: 'logout',
                onClick: handleLogout
              }
            ]}
          >
            <Button
              tabIndex={-1}
              title={userFullName}
              avatar={
                <Avatar
                  src={userAvatar}
                  size={1.75}
                  name={userFullName || ''}
                  fallbackMode="initials"
                />
              }
              endIcon="arrow-down"
            />
          </Dropdown>
        </Topbar>
      </Box>
      <Box display="flex" maxWidth="100vw">
        <Box className={classes.drawerWrapper} component="nav">
          {userWithoutMember ? (
            <Void />
          ) : (
            <Drawer
              className={classes.drawer}
              open={drawerOpen}
              onOpenToggle={() => {
                window.dispatchEvent(new Event('resize'));
                dispatch(toggleDrawerOpen());
              }}
              topItems={
                <>
                  {settings
                    .navigation_left({
                      intl,
                      organization,
                      defaultFilter
                    })
                    .map(item => {
                      const active = getDrawerItemActive(item);

                      return (
                        <Drawer.Item
                          component={Link}
                          active={active}
                          key={item.href}
                          icon={item.icon}
                          index={item.href}
                          title={item.caption}
                          aria-label={intl.formatMessage(
                            {
                              id: 'show_x',
                              defaultMessage: 'Show {entity}'
                            },
                            { entity: item.caption }
                          )}
                          href={item.href}
                        >
                          {item.submenu
                            ? item.submenu.map(submenuItem => (
                                <Drawer.SubItem
                                  component={Link}
                                  aria-label={intl.formatMessage(
                                    {
                                      id: 'show_x',
                                      defaultMessage: 'Show {entity}'
                                    },
                                    { entity: submenuItem.caption }
                                  )}
                                  href={submenuItem.href}
                                  key={submenuItem.href}
                                  active={getDrawerItemActive(submenuItem)}
                                  index={submenuItem.href}
                                  title={submenuItem.caption}
                                />
                              ))
                            : null}
                        </Drawer.Item>
                      );
                    })}
                </>
              }
            />
          )}
        </Box>
        <Box component="main" id={theme.contentId} className={classes.main}>
          {props.loading && (
            <Box
              className={classes.loadingIndicator}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LoadingIndicator loading />
            </Box>
          )}
          {props.error && (
            <BodyContainer>
              <Message type="error">{props.error}</Message>
              <Spacing y={1} />
            </BodyContainer>
          )}
          {props.warning && (
            <BodyContainer>
              <Message type="warning">{props.warning}</Message>
              <Spacing y={1} />
            </BodyContainer>
          )}
          {props.success && (
            <BodyContainer>
              <Message type="success">{props.success}</Message>
              <Spacing y={1} />
            </BodyContainer>
          )}
          {props.children}
        </Box>
      </Box>
      <BodyBottombar />
    </Box>
  );
}

Body.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  success: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Body.defaultProps = {
  children: undefined,
  loading: false,
  error: undefined,
  warning: undefined
};

Body.BottombarPortal = BodyBottombarPortal;
Body.Container = BodyContainer;
Body.Sidebar = BodySidebar;
Body.SidebarItem = BodySidebarItem;
Body.SidebarContainer = BodySidebarContainer;
