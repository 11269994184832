import PropTypes from 'prop-types';
import React from 'react';
import { Box, makeStyles } from 'groundkeeper-component-library';

const useStyles = makeStyles(theme => ({
  bodySidebarItem: {
    background: theme.color.background.grey
  }
}));

export default function BodySidebarItem(props) {
  const classes = useStyles(props);

  return (
    <Box className={classes.bodySidebarItem} p={2} pt={0} mb={2}>
      {props.children}
    </Box>
  );
}

BodySidebarItem.propTypes = {
  children: PropTypes.node
};

BodySidebarItem.defaultProps = {
  children: undefined
};
