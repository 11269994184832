import { createSelector } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';
import { Organization, Selector } from 'shared/Redux/interfaces';
import { organizationsEntity } from 'shared/Redux/schemas';
import {
  RootState,
  organizationSelectors,
  organizationMemberSelectors,
  consumerSelectors,
  providerSelectors,
  consumerMemberSelectors,
  providerMemberSelectors
} from 'shared/Redux/slices';

export default function selectOrganizationById(
  id: string
): Selector<Organization> {
  return createSelector(
    [
      (state: RootState) => organizationSelectors.selectById(state, id),
      organizationMemberSelectors.selectEntities,
      consumerSelectors.selectEntities,
      consumerMemberSelectors.selectEntities,
      providerSelectors.selectEntities,
      providerMemberSelectors.selectEntities
    ],
    (
      organization,
      organizationMembers,
      consumers,
      consumerMembers,
      providers,
      providerMembers
    ) => {
      const denormalized = denormalize(organization, organizationsEntity, {
        organizationMembers,
        consumerMembers,
        consumers,
        providers,
        providerMembers
      });

      return denormalized;
    }
  );
}
