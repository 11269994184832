import PropTypes from 'prop-types';
import React from 'react';
import { Box, makeStyles } from 'groundkeeper-component-library';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: props => (props.fullWidth ? '100%' : '70em'),
    width: '100%',
    margin: '0 auto'
  }
}));

export default function BodyContainer(props) {
  const classes = useStyles(props);

  return <Box className={classes.container}>{props.children}</Box>;
}

BodyContainer.propTypes = {
  children: PropTypes.node
};

BodyContainer.defaultProps = {
  children: undefined
};
