import {
  Box,
  Button,
  makeStyles,
  classNames
} from 'groundkeeper-component-library';
import { useEffect, useRef, useState } from 'react';
import BodyContainer from 'shared/App/components/Body/BodyContainer';
import { BOTTOMBAR_HEIGHT } from '.';

const useStyles = makeStyles(theme => ({
  bottombar: {
    background: theme.color.background.default,
    borderTop: `1px solid ${theme.color.border.light}`,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: BOTTOMBAR_HEIGHT,
    maxHeight: BOTTOMBAR_HEIGHT,
    justifyContent: 'center',
    zIndex: 98,
    [theme.mediaQueries.small]: {
      maxHeight: '80vh',
      '&.hidden': {
        minHeight: 0
      }
    }
  },
  bottombarItems: {
    position: 'relative',
    width: '100%',
    [theme.mediaQueries.small]: {
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      rowGap: theme.spacing(0.5),
      flexDirection: 'column-reverse',
      '&.hidden': {
        display: 'none'
      }
    }
  },
  toggleButton: {
    display: 'none',
    position: 'absolute',
    right: theme.spacing(2),
    top: 0,
    // TBD:Button height
    marginTop: '-33px',
    border: `1px solid ${theme.color.border.light}`,
    borderRadius: 0,
    background: theme.color.background.default,
    borderBottom: 'none',
    zIndex: 99,
    [theme.mediaQueries.small]: {
      display: 'block'
    }
  }
}));
export default function BodyBottombar() {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const bottombarItemsRef = useRef();

  useEffect(() => {
    // We have to wait here because children get set by
    // bottombar portal that is also client side rendered
    setTimeout(() => {
      setHasChildren(
        bottombarItemsRef.current?.children
          ? bottombarItemsRef.current.children.length > 0
          : false
      );
    }, 50);
  }, []);

  return (
    <Box
      display="flex"
      alignSelf="center"
      className={classNames([
        classes.bottombar,
        visible ? undefined : 'hidden'
      ])}
      flexShrink="0"
      position="sticky"
      bottom={0}
      width={1}
      p={1}
    >
      {hasChildren ? (
        <Button
          className={classes.toggleButton}
          icon
          onClick={() => setVisible(!visible)}
          size="large"
          aria-label="Fußleiste öffnen/schließen"
        >
          {visible ? 'arrow-down' : 'arrow-up'}
        </Button>
      ) : null}
      <BodyContainer>
        <Box
          id="bottombar"
          display="flex"
          ref={bottombarItemsRef}
          alignItems="center"
          className={classNames([
            classes.bottombarItems,
            visible ? undefined : 'hidden'
          ])}
          justifyContent="flex-end"
        />
      </BodyContainer>
    </Box>
  );
}

BodyBottombar.propTypes = {};

BodyBottombar.defaultProps = {};
