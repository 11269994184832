import { createSelector } from '@reduxjs/toolkit';
import { organizationSelectors } from '../../slices';
import { RootState } from 'shared/Redux/slices';
import getMemberType from 'shared/Redux/utils/getMemberType';

const getMember = (state: RootState) => state.auth.member;

export default createSelector(
  [getMember, organizationSelectors.selectAll],
  (member, organizations) => {
    const memberType = getMemberType(member);

    switch (memberType) {
      case 'organization':
        return organizations.filter(o => o.id === member['organization_id'])[0]
          ?.id;

      case 'provider':
        return organizations.filter(o => o.id === member['organization_id'])[0]
          ?.id;

      case 'consumer':
        return organizations.filter(o => o.id === member['organization_id'])[0]
          ?.id;

      default:
        return null;
    }
  }
);
