import { createSelector } from '@reduxjs/toolkit';

import getMemberType from 'shared/Redux/utils/getMemberType';
import { RootState } from 'shared/Redux/slices';

const getMember = (state: RootState) => state.auth.member;

export default createSelector([getMember], member => {
  return getMemberType(member);
});
