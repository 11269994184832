import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'shared/Redux/slices';

const getMember = (state: RootState) => state.auth.member;

export default createSelector([getMember], member => {
  if (member) {
    return false;
  }

  return true;
});
