import { createSelector } from '@reduxjs/toolkit';
import { providerSelectors } from '../../slices';
import { RootState } from 'shared/Redux/slices';
import getMemberType from 'shared/Redux/utils/getMemberType';

const getMember = (state: RootState) => state.auth.member;

export default createSelector(
  [getMember, providerSelectors.selectAll],
  (member, providers) => {
    const memberType = getMemberType(member);
    const firstProvider = providers?.length ? providers[0] : null;

    switch (memberType) {
      case 'organization':
        return firstProvider;

      case 'provider':
        return providers.filter(p => p.id === member['provider_id'])[0];

      case 'consumer':
        return firstProvider;

      default:
        return null;
    }
  }
);
