export default function formatImageUrl(url) {
  if (!url) return undefined;

  if (
    url.startsWith('http://') ||
    url.startsWith('https://') ||
    (url.startsWith('/') && url[1] !== '/')
  ) {
    return url;
  }

  return `/${url}`;
}
