import PropTypes from 'prop-types';
import React from 'react';
import { Box, makeStyles } from 'groundkeeper-component-library';

const useStyles = makeStyles(theme => ({
  bodySidebarContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    [theme.mediaQueries.small]: {
      flexWrap: 'wrap'
    }
  }
}));

export default function BodySidebarContainer(props) {
  const classes = useStyles();

  return <Box className={classes.bodySidebarContainer}>{props.children}</Box>;
}

BodySidebarContainer.propTypes = {
  children: PropTypes.node
};

BodySidebarContainer.defaultProps = {
  children: undefined
};
