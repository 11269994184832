import PropTypes from 'prop-types';
import React from 'react';
import { Box, makeStyles } from 'groundkeeper-component-library';

const useStyles = makeStyles(theme => ({
  bodySidebarItem: {
    flexShrink: 0,
    flexBasis: '33%',
    boxSizing: 'border-box',
    [theme.mediaQueries.small]: {
      flexBasis: '100%'
    }
  }
}));

export default function BodySidebar(props) {
  const classes = useStyles();

  return <Box className={classes.bodySidebarItem}>{props.children}</Box>;
}

BodySidebar.propTypes = {
  children: PropTypes.node
};

BodySidebar.defaultProps = {
  children: undefined
};
