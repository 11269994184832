import { createSelector } from '@reduxjs/toolkit';
import selectCurrentUserMember from './selectCurrentUserMember';

const selectLoggedInUserFullName = createSelector(
  selectCurrentUserMember,
  userMember =>
    userMember?.contacts.length
      ? `${userMember?.contacts[0].first_name} ${userMember?.contacts[0].last_name}`
      : ''
);

export default selectLoggedInUserFullName;
